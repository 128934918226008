import React from 'react'


const Popular = () => {


  return (
    <section className="pt-100 mb-0">
      <div className="container">
        <div className="row">
          <div className="col-lg-12 col-md-12 col-sm-12 col-12 text-center">
            <h2 className="heading-h2"><span className="bluecolor">What We Offer</span> With Our White label Crypto Wallet 
            </h2>
            <p className="pharagraph head">Explore our White label crypto  Wallet to find out the broad range of offerings we've integrated here entirely customized according to your Business.
            </p>
          </div>
        </div>
        <div className='row popular we-offer'>
            <div className='col-md-12 col-lg-3'>
                <h3>Limitless Cryptocurrencies</h3>
                <p className='pharagraph'>Our White-label Wallet is empowered with the most cutting-edge technology stack and ensures a seamless solution. Customized to handle unlimited cryptocurrencies, it's your go-to solution which offers user-friendly staking features to earn rewards and liquidity.</p>
            </div>
            <div className='col-md-12 col-lg-3'>
                <h3>Handle NFTs</h3>
                <p className='pharagraph'>White label Crypto Wallet is NFT-ready, allowing users to accumulate and securely store NFTs for a long. Multi-chain interoperability is supported by our White-label Wallet solution, increasing its accessibility.</p>
            </div>
            <div className='col-md-12 col-lg-3'>
                <h3>Transactions</h3>
                <p className='pharagraph'>The wallet has diverse transaction options that allow users to transfer both NFTs and crypto assets in versatile ways. Additionally, users can use the wallet's instant payment feature to settle their bills with cryptocurrency.</p>
            </div>
            <div className='col-md-12 col-lg-3'>
                <h3>Fiat Currency</h3>
                <p className='pharagraph'>We introduce the ability to use fiat money in the wallet seamlessly. It allows users to easily buy and sell crypto directly. This fiat-friendly approach encourages users to make the wallet their go-to choice for all payment needs.</p>
            </div>
        </div>
      </div>
    </section>
  )
}

export default Popular