import React from "react"
import ButtonComponent from "../ButtonComponent/ButtonComponent"

class ProcessOf extends React.Component {

  render() {
    return (

      <section className="process-of white-wallet pt-100 mb-0">
        <div className="gray-bg">
          <div className="container"> 
            <h2 className="heading-h2 text-center">
            <span className="bluecolor">Add-on Delights</span> Of Our Cryptocurrency Wallet Development - Upgrade Your Wallet Height!
            </h2>
            <p className="text-center">
            Elevate your crypto wallet experience with our exclusive Add-ons and drive your business to new heights. Check out our White label Crypto Wallet add-on features for a rapid upgrade.
            </p>
            <div className="d-lg-flex add-one">
              <div className="square" >
                <h3>Branding and Design </h3>
                <p className="pharagraph">
                Customize your crypto wallet by incorporating your company's logo and a vibrant look with color palettes, and unique visual elements. Our inherent design tools enable you to create a visually captivating UI. Bring your brand to life with a sleek and modern look or a more vibrant and playful design Etc.
                </p>
              </div>
              <div className="square">
                <h3>User Interface Configuration</h3>
                <p className="pharagraph">
                Personalize the UI of your wallet to offer a fantabulous user- experience. Select from wide layout options, navigation styles, and widget configurations that suit your business. We got you covered with features from simple to advanced functionalities to make you stand out from the crowd. 
                </p>
              </div>
              <div className="square" >
                <h3>Functionalities and Features</h3>
                <p className="pharagraph">
                Develop the functionalities and features according to your mindset with our white label wallet. Prefer an ample range of options such as transaction types, supported cryptocurrencies, security measures, and integration capabilities. 
                </p>
              </div>
            </div>
            <div className="d-lg-flex add-two">
              <div className="square" >
                <h3>Language and Localization</h3>
                <p className="pharagraph">
                Cover the global audience by customizing with languages and location settings of your wallet. Whether you target a specific region or cater to a diverse international user base, our White label cryptocurrency Wallet supports multilingual languages.
                </p>
              </div>
              <div className="square" >
                <h3>Customizable Notifications</h3>
                <p className="pharagraph">
                Curate the notification system of your crypto wallet with us. We’re here to customize your notification types like transaction updates, security alerts, promotional messages, etc...
                </p>
              </div>
            </div>
            <div className="mt-3 text-center">
                <ButtonComponent />
            </div>
          </div>
        </div>
      </section>

    )
  }
}

export default ProcessOf
