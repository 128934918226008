import React from 'react'



class Whatis extends React.Component {


  render() {

    return (
      <section className="whatis pt-0">
        <div className='gray-bg'>
        <div className="container">
              <h2 className="heading-h2 text-center"><span className='heading-h3 bluecolor'>What is</span> White Label Crypto Wallet?</h2>
            <div className="text-center">
              <p className="pharagraph text-center">The White-Label Crypto Wallet is a pre-built, customizable solution for securely managing cryptocurrencies, NFTs, stablecoins, and other digital assets. Extensively tested and ready for immediate deployment, it integrates with blockchain projects, crypto exchanges, investment platforms, and so on. Our wallet is equipped with advanced security features to safeguard against potential threats and attacks.
              </p>
              <p className="pharagraph text-center mb-0">Developing non-custodial wallets for mobile and desktop platforms is made simple with Coinsclone. With over a decade of experience, our experts deliver prompt solutions featuring advanced functionalities crafted to your business needs. We offer extensive customization options to make your wallet solution precisely aligned with your vision.  Get a 100% ideal wallet solution with Coinsclone. 
              </p>
            </div>
        </div>
        </div>
      </section>
    )
  }
}

export default Whatis