import React, { Component } from 'react'


class CoreFeatures extends Component {

  // Tab
  openTabSection = (evt, tabName) => {
    let i, tabcontent, tablinks;
    tabcontent = document.getElementsByClassName("tabs_items");
    for (i = 0; i < tabcontent.length; i++) {
      tabcontent[i].classList.remove("fadeIn");
      tabcontent[i].style.display = "none";
    }

    tablinks = document.getElementsByTagName("li");
    for (i = 0; i < tablinks.length; i++) {
      tablinks[i].className = tablinks[i].className.replace("current", "");
    }

    document.getElementById(tabName).style.display = "block";
    document.getElementById(tabName).className += " fadeIn animated";
    evt.currentTarget.className += "current";
  }

  render() {
    return (
      <section className="usr-admin-tab pt-100 mb-0">
        <div className="container">
            <h2 className="heading-h2 text-center"><span className="heading-h3">White label Crypto Wallet</span>
            <span className="bluecolor">Our Feature</span> Fiesta Right Here!</h2>
            <p className='text-center'>Delve into our flexible features which are categorized into a spectrum of simple and advanced options. We fuel your crypto wallet business for profitability. </p>
          <div className="row">
              <ul className="nav nav-tabs tabNav nav-pills" id="myTab" role='presentation'>
                <li className="current" role='presentation' onKeyDown={this.openTabSection}
                  onClick={(e) => this.openTabSection(e, 'tab1')}>
                  Basic Features
                </li>
                <li role='presentation' onKeyDown={this.openTabSection}
                  onClick={(e) => this.openTabSection(e, 'tab2')} >
                  Advanced Features
                </li>
              </ul>

              <div className="tab-content px-0">
                <div id="tab1" className="tab-pane tabs_items active">
                    <div className='d-flex flex-wrap justify-content-center'>
                        <div className='fea-card'>
                            <h3>Add Custom Tokens</h3>
                            <p className='pharagraph'>Manually add and manage the cryptos that are not supported automatically by the wallet.</p>
                        </div>
                        <div className='fea-card'>
                            <h3>Ledger Support</h3>
                            <p className='pharagraph'>Facilitates users to save and manage often-used crypto addresses using this feature.</p>
                        </div>
                        <div className='fea-card'>
                            <h3>Wallet connection</h3>
                            <p className='pharagraph'>Enabling secure communication and interaction between a web dApp and a mobile crypto wallet.</p>
                        </div>
                        <div className='fea-card'>
                            <h3>Two-Factor Biometric Authentication</h3>
                            <p className='pharagraph'>Biometric authentication, like fingerprint or facial recognition, or an OTP can provide security safeguards. It prevents strangers from accessing the wallet. </p>
                        </div>
                        <div className='fea-card'>
                            <h3>NFT Collectibles</h3>
                            <p className='pharagraph'>Offering Non-Fungible Tokens (NFT) and presenting them as owner for the digital asset items.</p>
                        </div>
                        <div className='fea-card'>
                            <h3>Multiple Wallet Support </h3>
                            <p className='pharagraph'>Users can create, and manage multiple wallets and also have a wallet dashboard to carry the essential information.</p>
                        </div>
                        <div className='fea-card'>
                            <h3>Native Fiat Currency Selection</h3>
                            <p className='pharagraph'>Choosing the native fiat currency of the user's choice and valuing for conversion. </p>
                        </div>
                        <div className='fea-card'>
                            <h3>Asset Management</h3>
                            <p className='pharagraph'>Users can quickly manage and keep an eye on their crypto assets promptly. This tracks user portfolios, and transaction histories and generates detailed reports on holdings and investment performance.</p>
                        </div>
                        <div className='fea-card'>
                            <h3>Export and Import Private Keys</h3>
                            <p className='pharagraph'>Users can protect their cryptos securely by exporting and importing the private keys. Promising your user, complete wallet control.</p>
                        </div>
                    </div>
                </div>
                <div id="tab2" className="tab-pane tabs_items">
                    <div className='d-flex flex-wrap justify-content-center'>
                        <div className='fea-card'>
                            <h3> Multichain Wallet Networks</h3>
                            <p className='pharagraph'>Our White label Crypto Wallet serves a multiverse of blockchain networks that allow users to access their cryptos in one place.</p>
                        </div>
                        <div className='fea-card'>
                            <h3>Cross and On-chain crypto swap</h3>
                            <p className='pharagraph'>Users can exchange cryptos directly on the blockchain as well as across several blockchain networks without relying on external exchange.</p>
                        </div>
                        <div className='fea-card'>
                            <h3>Chats</h3>
                            <p>Non-stop real-time communications and instant partnership for upgraded crypto asset management and support.</p>
                        </div>
                        <div className='fea-card'>
                            <h3>Multichain dApps Browser</h3>
                            <p className='pharagraph'>Our White-label Wallet offers decentralized application (DApps) browsers and access to DeFi services.</p>
                        </div>
                        <div className='fea-card'>
                            <h3>Real-time Tracking and Trading Insights</h3>
                            <p className='pharagraph'>Stay updated with prices and market trends. Our White-label Wallet enables tracking and sending notifications. It allows users to set price alerts ensuring they never miss an opportunity for market movements.</p>
                        </div>
                        <div className='fea-card'>
                            <h3>Prepaid Cards</h3>
                            <p className='pharagraph'>We allow access to prepaid debit cards which can be used for making payments. Users can also top up the card with crypto and obtain a fiat equivalent balance in the platform.</p>
                        </div>
                    </div>
                </div>
              </div>
          </div>
        </div>
      </section>
    );
  }
}

export default CoreFeatures