import React, { useState } from "react";
import { Accordion, Container } from 'react-bootstrap'



const FaqSection = () => {


  const [activeId, setActiveId] = useState('');

  function toggleActive(id) {
    if (activeId === id) {
      setActiveId(null);
    } else {
      setActiveId(id);
    }
  }

  return (
    <section className="faq pt-100 mb-5">
      <Container>
        <h2 className="heading-h2 text-center">Frequently Asked Questions?</h2>
        <Accordion defaultActiveKey={activeId}>
            <div className={activeId === '0' ? 'panel-wrap active-panel card' : 'panel-wrap card'}>
              <div className="panel-header card-header">

                <Accordion.Toggle onClick={() => toggleActive('0')} className="panel-toggle" eventKey="0">
                What is a White label Crypto Wallet?
                </Accordion.Toggle>
              </div>

              <Accordion.Collapse eventKey="0">
                <div className="panel-body card-body">White label Crypto Wallet is a ready-made software solution that enables you to promptly launch your own branded crypto wallet which makes it easier for the user to buy, sell, and swap the cryptos. You can customize according to your ideas with features such as multi-asset support, robust private key management, and a user-friendly interface for a hassle-free experience.
                </div>
              </Accordion.Collapse>
            </div>
            <div className={activeId === '1' ? 'panel-wrap active-panel card' : 'panel-wrap card'}>
              <div className="panel-header card-header">
                <Accordion.Toggle onClick={() => toggleActive('1')} className="panel-toggle"  eventKey="1">
                What are the Benefits of Developing a White label Crypto Wallet?
                </Accordion.Toggle>
              </div>
              <Accordion.Collapse eventKey="1">
                <div className="panel-body card-body">The benefits of purchasing a White label Crypto Wallet are it helps with user engagement and budget-friendly options. With no delay, you can easily launch your business. It is an easy way to create branding for your Crypto Wallet business.
                </div>
              </Accordion.Collapse>
            </div>
            <div className={activeId === '2' ? 'panel-wrap active-panel card' : 'panel-wrap card'}>
              <div className="panel-header card-header">
                <Accordion.Toggle onClick={() => toggleActive('2')} className="panel-toggle"  eventKey="2">
                What are the security Features of White-label Crypto Wallet
                </Accordion.Toggle>
              </div>

              <Accordion.Collapse eventKey="2">
                <div className="panel-body card-body">White label Crypto Wallet is empowered with robust security features such as HTTP authentication, Secure Private Key management, Data encryption, Two-factor authentication, SQL injection prevention, and more. You can also avail yourself of additional features like Face/Touch ID, Tracking History Transaction, etc.
                </div>
              </Accordion.Collapse>
            </div>
            <div className={activeId === '3' ? 'panel-wrap active-panel card' : 'panel-wrap card'}>
              <div className="panel-header card-header">
                <Accordion.Toggle onClick={() => toggleActive('3')} className="panel-toggle"  eventKey="3">
                What are the features of a White-label Crypto Wallet app?
                </Accordion.Toggle>
              </div>

              <Accordion.Collapse eventKey="3">
                <div className="panel-body card-body">White label Crypto Wallet consists of exquisite features like a Simple and Intuitive Interface, Multi-currency Support, Backup and Recovery, API integration to Exchanges, Real-time trading insights, Biometric Authentication, etc.
                </div>
              </Accordion.Collapse>
            </div>
            <div className={activeId === '4' ? 'panel-wrap active-panel card' : 'panel-wrap card'}>
              <div className="panel-header card-header">
                <Accordion.Toggle onClick={() => toggleActive('4')} className="panel-toggle"  eventKey="4">
                How Much Will It Cost to Develop a White-label Crypto Wallet?
                </Accordion.Toggle>
              </div>

              <Accordion.Collapse eventKey="4">
                <div className="panel-body card-body">The approximate cost to deploy a White-label Crypto Wallet ranges between $10,000 to $25,000. Depending upon the customization, and add-on features, the cost may slightly vary.
                </div>
              </Accordion.Collapse>
            </div>
            <div className={activeId === '5' ? 'panel-wrap active-panel card' : 'panel-wrap card'}>
              <div className="panel-header card-header">
                <Accordion.Toggle onClick={() => toggleActive('5')} className="panel-toggle"  eventKey="5">
                How Long Does it Take to Deploy a White label Crypto Wallet?
                </Accordion.Toggle>
              </div>

              <Accordion.Collapse eventKey="5">
                <div className="panel-body card-body">Approximately it will take 15- 30 days to deploy a White-label Crypto Wallet. For the complex customization and add-ons, the time may increase further.
                </div>
              </Accordion.Collapse>
            </div>
        </Accordion>
      </Container>

    </section>
  )
}

export default FaqSection