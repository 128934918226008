import React from 'react'



class OurVarious extends React.Component {


  render() {

    return (
      <section className="icon whitelabel-innove pt-100 secureof">
        <div className="container">
          <h2 className="main-heading"><span className='bluecolor'>Holistic Service</span> of Our White label Cryptocurrency Wallet Development</h2>
          <p className='text-center'>Discover the variety of connection possibilities offered by our White label Crypto Wallet in a unified ecosystem. Whether it's a financial institution, cryptocurrency exchange, or blockchain app, our crypto wallet offers all-rounder-adaptable integration.</p>
          <div className="row ">
            <div className='d-flex flex-wrap justify-content-evenly new-flex px-0'>
              <div className='leftdiv'>
                <h3 className='head3'>
                API Integration
                  </h3>
                <p className="pharagraph">White label cryptocurrency wallet makes it possible to integrate APIs without any hassle. We allow you to connect your backend infrastructure, enabling smooth communication and data exchange between your platform and our crypto wallet. This empowers you to unlock the full potential of our wallet's features.</p>
              </div>
              <div className='leftdiv'>
                <h3 className='head3'>
                Exchange Integration
                </h3>
                <p className="pharagraph">Immaculately integrate your crypto exchange with our White-label Wallet, providing your users with a straightforward way to make crypto trading. A single interface is provided to manage assets, keep an eye on market prices, and make transactions. This removes the need for multiple logins or platforms.</p>
              </div>
              <div className='leftdiv'>
                <h3 className='head3'>
                Payment Gateway Integration
                </h3>
                <p className="pharagraph">Establish a connection between your payment gateway with our White label Wallet to enable simple and secure payments straight from the wallet Interface. Increase customer comfort and reach by connecting your payment gateway and allowing users to easily transact with cryptos.</p>
              </div>
              <div className='leftdiv'>
                <h3 className='head3'>
                Decentralized Application (DApps) Integration
                </h3>
                <p className="pharagraph">Integrate your dApps with our Crypto Wallet to fuel the power of blockchain technology. This unlocks fresh possibilities for your dApps, fostering broader acceptance and elevating the overall end-user experience.</p>
              </div>
              <div className='leftdiv'>
                <h3 className='head3'>
                Third-party service Integration
                </h3>
                <p className="pharagraph">Integrate third-party services into White label Crypto Wallet such as identity verification providers, KYC/AML solutions, or analytics platforms. In addition, this embellishes security, meets regulatory requirements, and gains valuable insights into user behavior and transaction patterns.</p>
              </div>
              <div className='leftdiv'>
                <h3 className='head3'>
                Blockchain Network Integration
                </h3>
                <p className="pharagraph">With our White label Wallet, you can incorporate an enormous variety of blockchain networks and communicate with various blockchain protocols. You can offer a diverse spectrum of digital assets, apps, and smart contracts. This increases the user's flexibility, ultimately driving your business growth.</p>
              </div>
              </div>
          </div>
          <a href="/contact-us/" className="bluebtn mt-2 m-auto d-table">Free Trial</a>
        </div>
      </section>
    )
  }
}

export default OurVarious