import React from 'react'



const DevApproach = () => {


  return (
    <section className="devapproach pt-100 mb-0">
      <div className="container">
        <div className="row">
          <div className="col-lg-12 col-md-12 col-sm-12 col-12 text-center">
          <h2 className="heading-h2"><span className="heading-h3"><span className="bluecolor">Development Approach</span> of our</span>
              White Label Crypto Wallet</h2>
            <p className="pharagraph head">To achieve a centralized or decentralized wallet for web extensions, desktop applications, and apps, Coinsclone has the best development approach.
            </p>
          </div>
        </div>
        <div className="row table-content orderflex">
          <div className="col-lg-6 col-md-12  text-center order1" >
            <img width="377px" height="326px" src="https://coinsclone.mo.cloudinary.net/images/coinbase-new/requirement-gathering-planning.png" alt="Development Approach image1" />
          </div>
          <div className="col-lg-6 col-md-12 padl60 order2">
            <h3>Requirement Gathering</h3>
            <p className="pharagraph" >This is the first step we take in creating a White-label Wallet with our immersive clients. Our skilled developers will compile the requirements and provide the optimal solution for your crypto business.
            </p>
            <br />
            <h3>Planning </h3>
            <p className="pharagraph">We design cryptocurrency wallets with thorough planning. A well-structured workflow for optimal app development results. These development results are based on the needs of the clients, and we carry them out without any hassle.
            </p>
          </div>
        </div>
        <div className="row table-content orderflex">
          <div className="col-lg-6 col-md-12 padr40 order2">
            <h3 >UI/UX Designing</h3>
            <p className="pharagraph">Our impeccable team of creative designers will create a user-friendly crypto wallet. Based on the needs of the client, we will create a Layout. Only after confirming with the client, the UI/UX design is incorporated.</p>
            <br />
            <h3 >Development</h3>
            <p  className="pharagraph">In this development phase, our developers will work on the backend part of the White- label Crypto Wallet creation. We will optimize the full-fledged wallet by incorporating advanced functionalities and robust security mechanisms.
            </p>
          </div>
          <div className="col-lg-6 col-md-12  text-center order1">
            <img width="425px" height="373px" src="https://coinsclone.mo.cloudinary.net/images/coinbase-new/design-development.png" alt="Designing and Development image1" /></div>
        </div>
        <div className="row table-content orderflex mb-0">
          <div className="col-lg-6 col-md-12  text-center order1" >
            <img width="370px" height="328px" src="https://coinsclone.mo.cloudinary.net/images/coinbase-new/testing-deployment.png" alt="Testing and Deployment image1" />
          </div>
          <div className="col-lg-6 col-md-12 padl60 order2 mb-0">
            <h3 >Testing</h3>
            <p  className="pharagraph">We have an unerring testing team to check and verify the quality of the crypto wallet development. Our expert team will conduct scrupulously accurate testing numerous times to ensure a splendid product outcome.
            </p>
            <br />
            <h3 >Deployment</h3>
            <p  className="pharagraph mb-0">After successfully completing all these phases, our team will safely deliver the finished product and quickly deploy the wallet on the client's preferred server.
            </p>
          </div>
        </div>
      </div>
    </section>
  )
}

export default DevApproach